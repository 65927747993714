@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }

  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

/* overflow-x slider for card  */
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      /* display: none; */
      height: 8px;
      border-radius: 3px;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      /* -ms-overflow-style: none; */
      /* IE and Edge */
      scrollbar-width: 2px;
      /* Firefox */
    }
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d6d5da;
  font-family: "Poppins", sans-serif;
  background-image: url("./assets/wp12028624 (1).jpg");
  background-size: cover;
  background-attachment: fixed;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-lex {
  font-family: "Lexend Giga", sans-serif;
}

.temporary-bounce {
  -webkit-animation-iteration-count: 2.5;
  animation-iteration-count: 2.5;
}

.custom-shape-divider-bottom-1671925496 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1671925496 svg {
  position: relative;
  display: block;
  width: calc(188% + 1.3px);
  height: 59px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1671925496 .shape-fill {
  fill: #eef0f2;
}

/* scroll bar  */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  /* border-radius: 5px; */
}

*::-webkit-scrollbar-thumb {
  background-color: #cacaca;
  border-radius: 3px;
  border: 3px solid var(--primary);
}

/* preload hand shake  */
.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 0 70% 0;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
